import Vue from 'vue'
import VueRouter from 'vue-router'
import { directoryClientItemType } from '@/views/app/directory-constants'
import store from '../store'
import authService from '../services/auth.service'
import routes from './routes/index'

Vue.use(VueRouter)

export const subscriptionRoute = {
  path: '/subscription/:planCode',
  name: 'subscription',
  meta: { requiresAuth: true },
  component: () => import(/* webpackChunkName: "subscription" */ '@/views/subscription/Subscription.vue')
}

export const plansRoute = {
  path: '/plans',
  name: 'plans',
  meta: { requiresAuth: false },
  component: () => import(/* webpackChunkName: "plans" */ '@/views/subscription/Plans.vue')
}

export const termsAndConditionsRoute = {
  path: '/terms-and-conditions',
  name: 'terms-and-conditions',
  meta: { requiresAuth: false },
  component: () => import(/* webpackChunkName: "terms-and-conditions" */ '@/views/TermsAndConditions.vue')
}

const routesConfig = [
  routes.playerControlRoute,
  {
    path: '/player',
    name: 'Player',
    meta: { skipAuthValidation: true },
    component: () => import(/* webpackChunkName: "player" */ '../views/Player.vue')
  },
  routes.loginRoute,
  routes.registerRoute,
  routes.accountCreatedRoute,
  routes.errorRoute,
  {
    path: '/help',
    name: 'help',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
  },
  subscriptionRoute,
  plansRoute,
  termsAndConditionsRoute,
  {
    path: '/subscription/confirm',
    name: 'subscription-confirm',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "subscription-confirm" */ '../views/SubscriptionConfirm.vue')
  },
  {
    path: '/subscription/confirm-epayco/:transactionId',
    name: 'subscription-confirm-epayco',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "subscription-confirm-epayco" */ '../views/SubscriptionConfirm.vue')
  },
  routes.myBusinessRoute,
  {
    path: '/ec',
    name: 'connect',
    redirect: '/ec/dashboard',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "connect" */ '../views/connect/Index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'connect-dashboard',
        component: () => import(/* webpackChunkName: "connect-dashboard" */ '../views/connect/Dashboard.vue')
      }
    ]
  },
  {
    path: '/app',
    name: 'app',
    redirect: '/app/home',
    component: () => import(/* webpackChunkName: "mob" */ '../views/app/Index.vue'),
    meta: { skipAuthValidation: true },
    children: [
      {
        path: 'home',
        name: 'app-home',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-home" */ '../views/app/Home.vue')
      },
      {
        path: 'menu',
        name: 'app-menu',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-menu" */ '../views/app/Menu.vue')
      },
      {
        path: 'menu/:businessId',
        name: 'app-menu-by-business',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-menu" */ '../views/app/Menu.vue')
      },
      {
        path: 'menus',
        name: 'app-menus-directory',
        meta: {
          skipAuthValidation: true,
          onClickActionType: directoryClientItemType.menu,
          generalMenuButtonActive: false
        },
        component: () => import(/* webpackChunkName: "mob-directory" */ '../views/app/Directory.vue')
      },
      {
        path: 'directory',
        name: 'app-directory',
        meta: {
          skipAuthValidation: true,
          onClickActionType: directoryClientItemType.directToAssociated,
          generalMenuButtonActive: true
        },
        component: () => import(/* webpackChunkName: "mob-directory" */ '../views/app/Directory.vue')
      },
      {
        path: 'directory/:id',
        name: 'app-directory-detail',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-directory-detail" */ '../views/app/DirectoryDetail.vue')
      },
      {
        path: 'menu-preview',
        name: 'app-menu-preview',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-menu-preview" */ '../views/app/MenuPreview.vue')
      },
      {
        path: 'menu-preview/:businessId',
        name: 'app-menu-preview-by-business',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-menu-preview" */ '../views/app/MenuPreview.vue')
      },
      {
        path: 'music',
        name: 'app-music',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-screen-and-music" */ '../views/app/ScreenAndMusic.vue')
      },
      {
        path: 'screen',
        name: 'app-screen',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-screen-and-music" */ '../views/app/ScreenAndMusic.vue')
      },
      {
        path: 'orders',
        name: 'app-orders',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-order" */ '../views/app/Order.vue')
      },
      {
        path: 'ReportSalesByDate',
        name: 'app-ReportSalesByDate',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-ReportSalesByDate" */ '../views/app/ReportSalesByDate.vue')
      },
      {
        path: 'menuReports',
        name: 'app-MenuReports',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-MenuReports" */ '../views/my-business/MenuReport.vue')
      },
      {
        path: 'categories',
        name: 'app-categories',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-categories" */ '@/views/app/Categories.vue')
      },
      {
        path: 'categories/:businessId',
        name: 'app-categories-by-business',
        meta: { skipAuthValidation: true },
        component: () => import(/* webpackChunkName: "mob-categories" */ '@/views/app/Categories.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routesConfig
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next({
      path: '/',
      params: { nextUrl: to.fullPath }
    })
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.currentUser) next()
    else {
      authService.tryGetCurrentUserAndSetToken().then(user => {
        if (user) next()
        else next({ path: '/login', query: { redirect: to.fullPath } })
      }, error => {
        console.error(error)
        next({ path: '/error', query: { redirect: to.fullPath } })
      })
    }
  } else {
    if (to.matched.every(record => record.meta.skipAuthValidation)) {
      next()
    } else {
      authService.tryGetCurrentUserAndSetToken().then(_ => {
        next()
      }, () => {
        next({ path: '/error', query: { redirect: to.fullPath } })
      })
    }
  }
})

export default router
